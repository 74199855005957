<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="createForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          :form="$refs.createForm"
          :disabled="invalid"
          @submit="submit"
        >
          <n-input
            v-model="data"
            :fields="fields"
          />
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('create', 'reward')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import NInput from '@/components/NInput.vue'
import FormInput from './formInput'

const RewardRepository = Repository.get('reward')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        type: null,
        isEnable: true,
      },
      loading: false,
    }
  },
  computed: {
    fields() {
      const fields = []

      FormInput.forEach(item => {
        if (item.key === 'amount') {
          if (this.data.type === 'cash') {
            fields.push(item)
          }
        } else {
          fields.push(item)
        }
      })

      return [...fields]
    },
  },
  methods: {
    submit() {
      this.$refs.createForm.validate().then(success => {
        if (success) {
          this.loading = true
          RewardRepository.create(this.data)
            .then(response => {
              this.$router.push({
                name: 'view-reward',
                params: { id: response.data.data.id },
              })
            })
            .catch(error => {
              if (error.response?.status === 422) {
                this.$refs.createForm.setErrors(error.response?.data?.errors)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    back() {
      this.$router.back()
    },
  },
}
</script>
